import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"

import Header from "../components/es/header";
import Footer from "./../components/footer";

//Icon
import { BiArrowBack } from "react-icons/bi";

//Styles
import "./../styles/privacity.scss";

const Privacidad = () => (
    <div>
        <Header checked={""} />
        <SEO
            title="Aviso de Privacidad"
            description="Aviso de privacidad de datos en CIE, consultoría integral estructurada para negocios basada en Monterrey, México."
        />
        <section className="privacity">
            <Link to="/" className="link" > <BiArrowBack size="18" className="icon" /> Regresar </Link>
            <h1>Aviso de privacidad</h1>
            <article>
                <p>Aviso de privacidad para la protección de datos personales.</p>
                <p>En términos de lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, la aportación que haga de sus datos Personales a CIE constituye la aceptación de estos Términos y Condiciones:</p>
                <ul role="list">
                    <li role="listitem" >1.- El presente aviso tiene por objeto la protección de sus datos personales, mediante su tratamiento legítimo, controlado e informado, a efecto de garantizar su privacidad, así como su derecho a la autodeterminación informativa.</li>
                    <li role="listitem" >2.- Conforme al artículo 3, fracción V, de la Ley, se entiende por Datos Personales: Cualquier información concerniente a una persona física identificada o identificable.</li>
                    <li role="listitem" >3.- CIE hará uso de los datos para fines únicamente informativos para identificarle, comunicarle, contactarle y enviarle información.</li>
                    <li role="listitem" >4.- La temporalidad del manejo de sus Datos Personales será indefinida a partir de la fecha en que nos los proporcione.</li>
                    <li role="listitem" >5.- CIE se compromete a tratar su información con normas de confidencialidad y seguridad administrativa.</li>
                    <li role="listitem" >6.- En términos de lo establecido por el artículo 22 de la Ley, usted tiene derecho en cualquier momento a ejercer sus derechos de acceso, rectificación, cancelación y oposición al tratamiento de sus datos personales.</li>
                    <li role="listitem" >7.- Si opta a abandonar nuestro sitio web a través de enlaces a sitios web no pertenecientes a nuestra entidad, CIE no se hará responsable de las políticas de privacidad de dichos sitios web ni de las cookies que éstos puedan almacenar en el ordenador del usuario.</li>
                </ul>
            </article>
        </section>
        <Footer lang="es" />
    </div>
)

export default Privacidad;
